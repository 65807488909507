import React from "react";

export default ({ }) => <div>
  <h2>Instruction Manual</h2>

  <p>This short guide introduces all of the features of Isometrically.</p>

  <div className="button-container">
    <a className="button-style" href="https://s3.eu-west-2.amazonaws.com/elm-accelerated-downloads/manual.pdf">Download Manual</a>
  </div>
</div>;