import React from "react"
import dragon from "../img/dragon.svg"
import videogameworld from "../img/videogameworld.svg"
import Lime from "../img/Lime.svg"
import pc from "../img/pc.svg"
import sand from "../img/Sand World.svg"

import InstructionsDownload from "../components/InstructionsDownload"
import Layout from "../components/layout"

const GuidePage = () => (
  <Layout>
    <div className="container">
      <InstructionsDownload />

      <h2>Composing Shapes</h2>
      <img src={Lime} alt="A simple shape" />

      <h2>Creatures</h2>
      <img src={dragon} alt="Dragon" />

      <h2>Video Game Style World</h2>
      <img src={sand} alt="sand world" />
      <img src={videogameworld} alt="videogame style" />

      <h2>Technology</h2>
      <img src={pc} alt="a computer" />
    </div>
  </Layout>
)

export default GuidePage
